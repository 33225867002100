import styled from "styled-components";

import { colors } from "../../assets/colors";

export default styled.form`
    display: flex;
    background-color: #fff;
    flex-direction: column;
    justify-content: space-between;
    min-height: 500px;

    .error {
        color: red;
        font-size: 14px;
    }
    section {
        align-self: flex-end;
        height: 20px;
        padding: 5px 10px;
        margin-bottom: 20px;
    }
    div {
        align-self: center;
    }

    table {
        overflow-x: scroll;
        width: 100%;

        td {
            text-align: left;
            align-content: center;
            article {
                min-width: 200px;
                color: ${colors.roxo_oficial};
                font-weight: 400px;
                svg {
                    color: ${colors.roxo_oficial};
                }
            }
        }
    }
`;

export const Table = styled.table`
    width: 100%;
    table-layout: fixed;
`;

export const Cedula = styled.td`
    color: ${props => (props.color ? props.color : "#196f10")};
`;
