import React, { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import { Link } from "gatsby";
import { FaPlane } from "react-icons/fa";
import { RiTruckFill, RiShip2Fill } from "react-icons/ri";

import api from "../../services/api";
import Card from "../../components/Cards";
import Layout from "../../components/Layout";
import Modal from "../../components/Modals";
import SEO from "../../components/SEO";
import TitleC6 from "../../components/Typography/TitleC6";
import NewBudgetForm from "./components/NewBudgetForm";
import Paginate from "../../components/Paginate/Paginate";
import { Cedula } from "./styles";

const BudgetPage = () => {
    // const { unity } = useStaticQuery(graphql`
    //     query {
    //         unity: file(relativePath: { eq: "unity-purple.png" }) {
    //             childImageSharp {
    //                 fixed(height: 9, width: 16) {
    //                     ...GatsbyImageSharpFixed_noBase64
    //                 }
    //             }
    //         }
    //     }
    // `);

    const [action, setAction] = useState(1);
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [meta, setMeta] = useState({
        current_page: 1,
    });
    const [openNewModal, setOpenNewModal] = useState(false);

    const actionNewModal = () => {
        setOpenNewModal(!openNewModal);
    };

    const loadList = () => {
        setLoading(true);
        api.get("quote/list?page=" + (meta.current_page > 0 ? meta.current_page : 1) + "&", {
            headers: {
                Authorization: "Bearer " + window.localStorage.getItem("session-token"),
            },
        }).then(response => {
            setList(response.data.data);
            setMeta(response.data.meta);
            setLoading(false);
        });
    };

    function formatStatus(status) {
        switch (status) {
            case "CREATED":
                return "#707070";
            case "ON_REVIEW_LEGAL":
                return "#F8991C";
            case "ON_REVIEW_OPERATIONAL":
                return "#F8991C";
            case "ON_REVIEW_CLIENT":
                return "#F8991C";
            case "APPROVED":
                return "#00a661";
            case "REJECTED":
                return "#FB1B41";
            case "CANCELLED":
                return "#707070";
            default:
                return "#707070";
        }
    }

    useEffect(() => {
        loadList();
    }, [action]);

    return (
        <Layout>
            {openNewModal && (
                <Modal
                    onClose={actionNewModal}
                    size="xs:w-11/12 lg:w-10/12 min-h-80%"
                    show={openNewModal}
                    title={<h4>NOVA COTAÇÃO</h4>}>
                    <NewBudgetForm
                        onSuccess={() => {
                            actionNewModal();
                            loadList();
                        }}
                    />
                </Modal>              
            )}
            <SEO title="Cotação" />
            <div className="p-4">
                <Card>
                    <div className="inline-block w-1/2">
                        <TitleC6>Cotação</TitleC6>
                    </div>
                    <div className="inline-block w-1/2">
                        <button
                            onClick={actionNewModal}
                            className="inline-block float-right bg-roxo_oficial rounded-md my-1 text-white outline-none p-2">
                            <span>Nova cotação</span>
                        </button>
                    </div>
                    <div className="overflow-auto">
                        <table className="table-auto mt-10 w-full text-left">
                            <thead className="border-b border-roxo_oficial">
                                <tr>
                                    <th className="text-roxo_oficial">Referência</th>
                                    <th align="center" className="text-roxo_oficial px-3">
                                        Tipo
                                    </th>
                                    <th className="text-roxo_oficial w-30">Processo</th>
                                    <th className="text-roxo_oficial w-40">Carga disponível</th>
                                    <th className="text-roxo_oficial w-64">Origem</th>
                                    <th className="text-roxo_oficial w-64">Destino</th>
                                    <th className="text-roxo_oficial">Valor invoice</th>
                                    <th className="text-roxo_oficial">Solicitado por</th>
                                    <th className="text-roxo_oficial">Status</th>
                                    <th className="text-roxo_oficial">Ação</th>
                                </tr>
                            </thead>
                            <tbody>
                                {list.map(item => (
                                    <>
                                        <tr key={item.identifier}>
                                            <td>
                                                {item.reference}{" "}
                                                <small>{`(${item.name})`}</small>
                                            </td>
                                            <td align="center" className="px-3">
                                                {item.transportModalitySlug === "air" && (
                                                    <span title="Aéreo">
                                                        <FaPlane className="text-roxo_oficial fill fill-current w-6 h-4 bg-white px-1 mb-1" />
                                                    </span>
                                                )}
                                                {item.transportModalitySlug === "sea" && (
                                                    <span title="Marítimo">
                                                        <RiShip2Fill className="text-roxo_oficial fill fill-current w-6 h-4 bg-white px-1 mb-1" />
                                                    </span>
                                                )}
                                                {item.transportModalitySlug === "ground" && (
                                                    <span title="Terrestre">
                                                        <RiTruckFill className="text-roxo_oficial fill fill-current w-6 h-4 bg-white px-1 mb-1" />
                                                    </span>
                                                )}
                                            </td>
                                            <td>{item.processCode ? item.processCode : "Sem processo"}</td>
                                            <td>{item.availableDateFormatted}</td>
                                            <td>
                                                {item.originPortName && (
                                                    <>
                                                        {item.originPortName} ({item.originPortCountry})
                                                    </>
                                                )}
                                            </td>
                                            <td>
                                                {item.destinyPortName && (
                                                    <>
                                                        {item.destinyPortName} ({item.destinyPortCountry})
                                                    </>
                                                )}
                                            </td>
                                            <td>
                                                {item.invoiceValueFormatted}
                                                <br />
                                                {item.cbmCubicMeters && item.cbmCubicMeters + " cbm"}
                                            </td>
                                            <td>{item.requiredByName}</td>
                                            <Cedula color={formatStatus(item.status)}>{item.statusReadable}</Cedula>
                                            <td>
                                                <Link
                                                    to={`/cotacao/detalhes/${item.identifier}`}
                                                    state={{ prevPath: window.location.pathname, data: item }}>
                                                    <button className="bg-C1 rounded-md my-1 text-roxo_oficial outline-none hover:bg-C3 p-2 w-24">
                                                        <span>Visualizar</span>
                                                    </button>
                                                </Link>
                                            </td>
                                        </tr>
                                    </>
                                ))}
                                {list.length > 0 && meta.total_pages > 1 && (
                                    <tr>
                                        <td colspan="11" className="text-center">
                                            <Paginate
                                                meta={meta}
                                                setMeta={setMeta}
                                                action={action}
                                                setAction={setAction}
                                                showDetails={true}
                                            />
                                        </td>
                                    </tr>
                                )}
                                {list.length === 0 && !loading && (
                                    <tr>
                                        <td colSpan="10" className="text-center pt-4">
                                            Nenhuma cotação encontrada
                                        </td>
                                    </tr>
                                )}
                                {loading && (
                                    <tr>
                                        <td colSpan="10" className="text-center pt-4">
                                            <ClipLoader />
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </Card>
            </div>
        </Layout>
    );
};

export default BudgetPage;